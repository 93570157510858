import QueryString from "qs";
import { useSearchParams } from "react-router-dom";

export const useFilterParams = (initialValue?: Record<string, any>) => {
  const [searchParams, setSearchParams] = useSearchParams(
    initialValue ? QueryString.stringify(initialValue) : undefined
  );

  const setFilterParams = (mutationFn: (params: any) => any) => {
    const parsed = QueryString.parse(searchParams.toString());

    const newParams = mutationFn(parsed);

    setSearchParams(
      QueryString.stringify(
        Object.fromEntries(Object.entries(newParams).filter(([_, value]) => !!value))
      )
    );
  };

  const filterParams = QueryString.parse(searchParams.toString()) as { [key: string]: any; };

  return { filterParams, setFilterParams };
};
