export default [
  { label: "Status", value: "status" },
  { label: "Cod. Fatura", value: "number" },
  { label: "Vlr. Total", value: "total_amount", className: "text-green-400" },
  { label: "N. Transações", value: "events_count" },
  { label: "Abertura", value: "opened_at" },
  { label: "Fechamento", value: "closed_at" },
  { label: "Vencimento", value: "due_at" },
  { label: "Ações", value: "actions", className: "w-9" },
];
