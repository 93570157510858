import usePrivateAxios from '@/common/api/usePrivateAxios';
import { ErrorResponse } from '@/common/types/errorResponse';
import { useMutation } from '@tanstack/react-query';

interface UseReportsSubmit {
  onSuccess?: () => void;
  onError?: (error: ErrorResponse) => void;
}

export const useReportSubmit = ({ onSuccess, onError }: UseReportsSubmit) => {
  const privateAxios = usePrivateAxios();

  return useMutation({
    mutationKey: ['reportSubmit'],
    mutationFn: async ({
      filters,
      report_type,
    }: {
      filters: { [key: string]: any; },
      report_type: string;
    }) => {
      await privateAxios.post(`/reports/entries/submit`, {
        ...filters,
        report_type,
      });
    },
    onSuccess,
    onError,
  });
};
