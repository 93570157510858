import { formatDateTime } from "@/common/utils/formatters";
import { DownloadIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { FiscalDocumentData } from "../types/fiscalDocument.type";
import { billingFiscalDocumentsStatus } from "../constants/billFiscalDocumentStatus.constant";

export class BillFiscalDocumentsTransformer {
  static tableTransformer() {
    return (fiscalDocuments: FiscalDocumentData[]) => {
      return fiscalDocuments.map((fiscalDocument) => ({
        ...fiscalDocument,
        status: fiscalDocument?.status ? billingFiscalDocumentsStatus[fiscalDocument?.status as keyof typeof billingFiscalDocumentsStatus] : '',
        issued_at: fiscalDocument.issued_at ? formatDateTime(new Date(fiscalDocument.issued_at)) : '',
        canceled_at: fiscalDocument.canceled_at ? formatDateTime(new Date(fiscalDocument.canceled_at)) : '',
        actions: fiscalDocument.link ? <Link to={fiscalDocument.link}><DownloadIcon /></Link> : '',
      }));
    };
  }
}
