import Table from "@/common/components/Table";
import { useFilterParams } from "@/common/hooks/useFilterParams";
import billFiscalDocumentsTableColumns from "../../constants/billFiscalDocumentsTableColumns.constant";
import { useParams } from "react-router-dom";
import { useBillingBillFiscalDocuments } from "../../hooks/useBillingBillFiscalDocuments";
import { Mapper } from "@/common/services/mapper";
import { twMerge } from "tailwind-merge";
import { useBillingBillPayments } from "../../hooks/useBillingBillPayments";
import billPaymentsTableColumns from "../../constants/billPaymentsTableColumns.constant";
import { BillFiscalDocumentsTransformer } from "../../services/billFiscalDocumentsTransformer";
import { BillPaymentsTransformer } from "../../services/billPaymentsTransformer";
import useBillingBill from "../../hooks/useBillingBill";
import DropdownMenu from "@/common/components/DropdownMenu";

const DocumentsTab = () => {
  const { id } = useParams();

  const { filterParams, setFilterParams } = useFilterParams();

  const {
    data: bill,
  } = useBillingBill({ id });

  const { data: fiscalDocuments, meta: fiscalDocumentsMeta, isPending: fiscalDocumentsIsPending } = useBillingBillFiscalDocuments({
    filters: {
      id,
      page: Number(filterParams.page || 1),
    },
    mapper: Mapper.mapWithTransformer({ transformer: BillFiscalDocumentsTransformer.tableTransformer() }),
  });

  const { data: payments, meta: paymentsMeta, isPending: paymentsIsPending } = useBillingBillPayments({
    filters: {
      id,
      page: Number(filterParams.paymentsPage || 1),
    },
    mapper: Mapper.mapWithTransformer({ transformer: BillPaymentsTransformer.tableTransformer() }),
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-end gap-3">
        <DropdownMenu
          triggerLabel="Baixar fatura"
          itemClassName="min-w-[160px]"
          options={[
            {
              label: 'PDF',
              onClick: () => bill?.pdf?.url && window.open(bill?.pdf?.url, '_blank'),
              disabled: !bill?.pdf?.url,
            },
            {
              label: 'CSV',
              onClick: () => bill?.csv?.url && window.open(bill?.csv?.url, '_blank'),
              disabled: !bill?.csv?.url,
            },
          ]}
        />
      </div>

      <div className="flex flex-col gap-4">
        <h5>Notas Fiscais</h5>

        <Table
          className={twMerge('max-h-[210px]', fiscalDocuments?.length === 0 ? 'overflow-hidden [&>*]:-mt-20 pt-4' : '')}
          rows={fiscalDocuments}
          columns={billFiscalDocumentsTableColumns}
          isLoadingData={fiscalDocumentsIsPending}
          page={fiscalDocumentsMeta.current_page}
          totalPages={fiscalDocumentsMeta.total_pages}
          totalCount={fiscalDocumentsMeta.total}
          hasPagination
          setPage={(page) => setFilterParams((prev) => ({ ...prev, fiscalDocumentsPage: page }))}
          rowEmptyValue="--"
        />
      </div>

      <div className="flex flex-col gap-4">
        <h5>Documentos de cobranças</h5>

        <Table
          className={twMerge('max-h-[210px]', payments?.length === 0 ? 'overflow-hidden [&>*]:-mt-20 pt-4' : '')}
          rows={payments}
          columns={billPaymentsTableColumns}
          isLoadingData={paymentsIsPending}
          page={paymentsMeta.current_page}
          totalPages={paymentsMeta.total_pages}
          totalCount={paymentsMeta.total}
          hasPagination
          setPage={(page) => setFilterParams((prev) => ({ ...prev, paymentsPage: page }))}
          rowEmptyValue="--"
        />
      </div>
    </div>
  );
};

export default DocumentsTab;
