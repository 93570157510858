import { forwardRef } from "react"
import ReactSelect, { Props }  from 'react-select'
import { twMerge } from "tailwind-merge"
import { badgeControlStyles, controlStyles, disabledControlStyles, styles } from "./styles"

export interface SelectProps extends Props {
  label?: string,
  markAsRequired?: boolean
  disabled?: boolean,
  options?: { label: string | JSX.Element, value: number | string, isDisabled?: boolean }[]
  badge?: boolean
}

const Select = forwardRef<HTMLElement,SelectProps>(({
  label,
  markAsRequired,
  className,
  disabled = false,
  options = [],
  placeholder = 'Selecione',
  badge = false,
  noOptionsMessage,
  ...props
}) => (
  <div className={className}>
    <label className={twMerge("relative flex flex-col items-start justify-center", )}>
      {label && <p className={twMerge('text-xs', disabled ? 'text-neutral-4' : '', 'mb-1')}>{label}{markAsRequired && <span className="text-danger-soft"> *</span>}</p>}
      <ReactSelect
        options={options}
        styles={styles}
        classNames={badge ? badgeControlStyles : ((disabled || props.isDisabled) ? disabledControlStyles : controlStyles)}
        noOptionsMessage={noOptionsMessage ? noOptionsMessage : () => 'Nenhuma opção'}
        placeholder={placeholder}
        {...props} />
    </label>
  </div>
))

export default Select