import ListFilters from "@/common/components/ListFilters";
import ListHeader from "@/common/components/ListHeader";
import Table from "@/common/components/Table";
import TextField from "@/common/components/TextField";
import { DatePickerWithRange } from "@/common/components/ui/dateRangePicker";
import { useCompanies } from "@/common/hooks/queries/useCompanies";
import useDebounce from "@/common/hooks/useDebounce";
import { useFilterDate } from "@/common/hooks/useFilterDate";
import { useFilterParams } from "@/common/hooks/useFilterParams";
import { CompaniesTransformer } from "@/common/services/companiesTransformer";
import { Mapper } from "@/common/services/mapper";
import { Option } from "@/common/types/option";
import { billingEventsFiscalDocumentsStatusOptions } from "@/features/billing/constants/eventsFiscalDocumentsStatusOptions.constant";
import { fiscalDocumentGroupsDefaultColumns } from "@/features/billing/constants/fiscalDocumentGroupsDefaultColumns.constant";
import { useBillingFiscalDocumentGroups } from "@/features/billing/hooks/useBillingFiscalDocumentGroups";
import { BillingFiscalDocumentGroupsTransformer } from "@/features/billing/services/fiscalDocumentGroupsTransformer";
import { Search, TablePropertiesIcon } from "lucide-react";

const BillingFiscalDocumentGroupsList = () => {
  const { filterParams, setFilterParams } = useFilterParams();
  const { date, formattedDate } = useFilterDate(filterParams);

  const [search, setSearch] = useDebounce(filterParams.number || '');
  const [searchClientCompany, setSearchClientCompany] = useDebounce('');

  const { data: clientCompaniesOptions } = useCompanies({
    filters: { search: searchClientCompany },
    mapper: Mapper.mapWithTransformer({ transformer: CompaniesTransformer.optionsTransformer() }),
  });

  const { data: billingEvents, meta, isFetching: billingEventsIsFetching } = useBillingFiscalDocumentGroups({
    filters: {
      page: filterParams.page || 1,
      search,
      createdAtStartDate: formattedDate.from,
      createdAtEndDate: formattedDate.to,
      clientCompanyId: filterParams.clientCompany?.value,
      clientBranchId: filterParams.clientCompany?.value && filterParams.clientBranch?.value,
      fiscalDocumentStatus: filterParams.status?.value,
      fiscalDocumentRequirement: 'per_bill',
    },
    mapper: Mapper.mapWithTransformer({ transformer: BillingFiscalDocumentGroupsTransformer.tableTransformer() })
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between">
        <ListHeader
          Icon={<TablePropertiesIcon className="w-10 h-10" strokeWidth={1} />}
          title="Recolha de notas por lote"
        />
      </div>

      <div className="h-[calc((100vh-88px-2rem-1.5rem))] flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <TextField
            LeftIcon={<Search />}
            placeholder="Pesquisar"
            className="rounded-sm shadow-[4px_0px_16px_0px_rgba(0,_0,_0,_0.5)]"
            onChange={(e) => {
              setSearch((e.target as HTMLInputElement).value);
              setFilterParams((prev) => ({ ...prev, search: (e.target as HTMLInputElement).value }));
            }}
            defaultValue={filterParams.search}
          />

          <div className="flex items-center justify-start gap-2">
            <DatePickerWithRange date={date} setDate={(date) => setFilterParams((prev) => ({ ...prev, date }))} />

            <ListFilters
              filters={[
                {
                  className: '',
                  onChange: (status) => setFilterParams((prev) => ({ ...prev, status })),
                  defaultValue: filterParams.status,
                  options: [{ label: 'Todas', value: '' }, ...billingEventsFiscalDocumentsStatusOptions],
                  placeholder: 'Status'
                },
                {
                  className: '',
                  onChange: (clientCompany) => setFilterParams((prev) => ({ ...prev, clientCompany, clientBranch: null })),
                  onInputChange: setSearchClientCompany,
                  defaultValue: filterParams.clientCompany,
                  options: [{ label: 'Todas', value: '' }, ...clientCompaniesOptions as Option[]],
                  placeholder: 'Empresa'
                },
              ]}
            />
          </div>
        </div>

        <Table
          columns={fiscalDocumentGroupsDefaultColumns}
          isLoadingData={billingEventsIsFetching}
          page={Number(filterParams.page) || 1}
          rows={billingEvents}
          totalPages={meta.total_pages}
          totalCount={meta.total}
          hasPagination
          setPage={(page) => setFilterParams((prev) => ({ ...prev, page }))}
          rowEmptyValue="--"
        />
      </div>
    </div>
  );
};

export default BillingFiscalDocumentGroupsList;
