import Button from "@/common/components/Button";
import { FileUploader } from "@/common/components/FileUploader";
import Modal from "@/common/components/Modal";
import { checkIsXml } from "@/common/utils/fileValidator";
import { useRef, useState } from "react";
import useBillingEventUploadFiscalDocumentForConsumption from "../../hooks/useBillingEventUploadFiscalDocumentForConsumption";
import { ModalMessageSimple } from "@/common/components/ModalMessageSimple";
import { useQueryClient } from "@tanstack/react-query";

const UploadFiscalDocument = ({ eventId }: { eventId: string; }) => {
  const queryClient = useQueryClient();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [messages, setMessages] = useState<string[] | boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const [file, setFile] = useState<Blob>();
  const [fileName, setFileName] = useState('');

  const uploadFiscalDocumentRef = useRef(null);

  const clearFile = () => {
    setFile(undefined);
    setFileName('');
  };

  const { mutate: uploadFiscalDocumentForConsumption } = useBillingEventUploadFiscalDocumentForConsumption({
    id: eventId,
    onSuccess: () => {
      clearFile();
      setModalIsOpen(false);
      setIsError(false);
      setMessages(["Nota enviada com sucesso!"]);
    },
    onError: (error) => {
      if (error.response?.data.errors.base) {
        clearFile();
        setModalIsOpen(false);
        setIsError(true);
        setMessages(error.response?.data.errors.base);
      }
    }
  });

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        setIsOpen={(value) => {
          setModalIsOpen(value);
          clearFile();
        }}
        Trigger={(
          <Button>Upload de nota</Button>
        )}
        title="Nova nota fiscal"
        footer="Confirmar upload manual"
        footerClassName="bg-primary-blue text-white-800"
        onConfirm={() => file && uploadFiscalDocumentForConsumption(file)}
        body={(
          <FileUploader
            ref={uploadFiscalDocumentRef}
            className="w-full bg-yellow-op-2 text-white-400 border-none rounded-lg"
            title="Anexe a nota fiscal"
            id="upload_fiscal_document"
            accept="application/xml"
            onChange={(e) => {
              const file = (e.target as HTMLInputElement & { files: FileList; }).files?.[0];

              if (!file || !checkIsXml(file)) {
                return;
              }

              setFileName(file.name);
              setFile(file);
            }}
            fileName={fileName ? fileName : undefined}
            currentFileUrl={file ? URL.createObjectURL(file) : undefined}
          />
        )}
      />

      {messages && (
        <ModalMessageSimple
          messages={messages as string[]}
          setMessages={setMessages}
          isError={isError}
          onClose={() => {
            queryClient.invalidateQueries({ queryKey: ['billingEvent', eventId] });
            queryClient.invalidateQueries({ queryKey: ['billingEvents'] });
          }}
        />
      )}
    </>
  );
};

export default UploadFiscalDocument;
