import Box from '@/common/components/Box';
import Select from '@/common/components/Select';
import TextField from '@/common/components/TextField';
import { userStatusTranslation } from '@/common/constants/userStatus.constants';
import { useCurrentUser } from '@/common/hooks/useCurrentUser';
import { dateTransformer } from '@/common/utils/dateTransformer';
import { formatCpf, formatPhoneNumber } from '@/common/utils/formatters';
import RequestPasswordReset from '@/features/users/components/RequestPasswordReset';
import { useEstablishmentUser } from '@/features/users/hooks/useEstablishmentUser';
import { useParams } from 'react-router-dom';

const GeneralInformationTab = () => {
  const { id: userId } = useParams();

  const { data: userMe } = useCurrentUser({});

  const { data: establishmentUserData } = useEstablishmentUser({ id: userId || '' });

  const userPermissionsSet = new Set(userMe?.permissions?.map(({ operation_klass }) => operation_klass));

  return (
    <>
      <Box className="flex flex-col gap-4 shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)] overflow-y-auto max-h-[calc(100vh-192px)]">
        <p className="text-lg font-bold">Dados pessoais</p>
        <div className="flex gap-2 w-full justify-between">
          <TextField
            label="CPF"
            className="w-3/4"
            value={
              establishmentUserData?.cpf
                ? formatCpf(establishmentUserData?.cpf)
                : ''
            }
            disabled
          />
          <TextField
            label="Data de nascimento"
            className="w-1/4"
            value={
              establishmentUserData?.date_of_birth
                ? dateTransformer(establishmentUserData?.date_of_birth)
                : ''
            }
            disabled
          />
        </div>
        <div className="flex gap-2 w-full justify-between">
          <TextField
            label="Nome"
            className="w-3/4"
            value={establishmentUserData?.full_name}
            disabled
          />
          <Select
            label="Perfil"
            className="w-1/4"
            value={establishmentUserData?.user_roles?.map(({ role }) => ({
              label: role.display_name,
              value: role.id,
            }))}
            isMulti
            isDisabled
          />
        </div>
        <TextField
          label="Status"
          value={
            userStatusTranslation[
            (establishmentUserData?.status ||
              'default') as keyof typeof userStatusTranslation
            ]
          }
          disabled
        />
        <TextField
          label="Matrícula"
          value={establishmentUserData?.enrollment_number}
          disabled
        />
        <TextField
          label="Telefone"
          value={establishmentUserData?.phone?.number ? formatPhoneNumber(establishmentUserData?.phone?.number) : ''}
          disabled
        />
        <TextField
          label="Email"
          value={establishmentUserData?.email}
          disabled
        />

        {establishmentUserData && userMe?.id !== userId && userPermissionsSet.has('Establishment::Users::RequestPasswordReset') && (
          <div className="w-full rounded-sm p-4 bg-white-100">
            <div className="flex w-full items-center justify-between">
              <p>Senha</p>

              <RequestPasswordReset id={userId!} />
            </div>
          </div>
        )}
      </Box>
    </>
  );
};

export default GeneralInformationTab;
