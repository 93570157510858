import { formatCurrency, formatDate, formatDateTime } from "@/common/utils/formatters";
import { DownloadIcon } from "lucide-react";
import { PaymentData } from "../types/payment.type";
import { Link } from "react-router-dom";
import { billPaymentStatus } from "../constants/billPaymentStatus.constant";
import { billPaymentType } from "../constants/billPaymentType.constant";

export class BillPaymentsTransformer {
  static tableTransformer() {
    return (payments: PaymentData[]) => {
      return payments.map((payment) => ({
        ...payment,
        status: payment?.status ? billPaymentStatus[payment?.status as keyof typeof billPaymentStatus] : '',
        type: payment.type ? (billPaymentType[payment.type as keyof typeof billPaymentType] || payment.type) : '',
        issued_at: payment.data?.issued_at && formatDate(new Date(payment.data.issued_at)),
        paid_total: payment?.paid_total ? formatCurrency(Number(payment.paid_total)) : '',
        paid_at: payment?.paid_at ? formatDateTime(new Date(payment.paid_at)) : '',
        canceled_at: payment?.canceled_at ? formatDateTime(new Date(payment.canceled_at)) : '',
        title_number: payment.data?.title_number,
        actions: payment.pdf?.url && (
          <Link to={payment.pdf.url}>
            <DownloadIcon />
          </Link>
        )
      }));
    };
  }
}
