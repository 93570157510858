import usePrivateAxios from "@/common/api/usePrivateAxios";
import { defaultTransformerResponse } from "@/common/constants/defaultTransformerResponse.constant";
import { Mapper } from "@/common/services/mapper";
import { MetaResponse } from "@/common/types/metaResponse";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

type UseBillingBillsProps<T> = {
  filters?: {
    page?: number;
    search?: string;
    status?: string;
    startDate?: string;
    endDate?: string;
  };
  mapper?: (data: any) => T,
};

export const useBillingBills = <T,>({
  filters: {
    page,
    search,
    status,
    startDate,
    endDate,
  } = {},
  mapper = Mapper.default<T>(),
}: UseBillingBillsProps<T>) => {
  const privateAxios = usePrivateAxios();

  const {
    data: { data, meta } = defaultTransformerResponse<T>(),
    ...rest
  } = useQuery({
    queryKey: [
      'billingEvents',
      page,
      status,
      startDate,
      endDate,
      search,
    ],
    queryFn: () => {
      return privateAxios.get('/billing/bills', {
        params: {
          page,
          search,
          'q[status_eq]': status,
          'q[opened_at_gteq]': startDate,
          'q[closed_at_lteq]': endDate
        }
      });
    },
    select: (data: { data: { data: any[], meta: MetaResponse; }; }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      };
    },
    placeholderData: keepPreviousData,
  });

  return { data, meta, ...rest };
};
