import { ChartLegend } from '@/common/components/ChartLgeend'
import { ChartContainer } from '@/common/components/ui/chart'
import { ChartData } from '@/common/types/chart.type'
import { barChartLabelFormatter } from '@/common/utils/barChartLabelFormatter'
import { cn } from '@/common/utils/cn'
import { Bar, BarChart as BarChartComponent, CartesianGrid, LabelList, XAxis, YAxis } from 'recharts'

export interface BarChartProps {
  chartData: ChartData
  className?: string
}

export const BarChart = ({ chartData, className }: BarChartProps) => {
  const legend =
    Object.keys(chartData.series_settings).length > 1 &&
    Object.values(chartData.series_settings).some(({ label }) => label)
      ? Object.keys(chartData.series_settings).map((key) => ({
          label: chartData.series_settings[key].label,
          color: chartData.series_settings[key].color,
        }))
      : []

  return (
    <div
      className={cn('w-full bg-white-200 rounded-lg p-0 overflow-hidden')}
      style={{ gridColumn: `span ${chartData.width}` }}
    >
      {chartData.kind !== 'value' && (
        <div className="flex flex-col w-full gap-2 bg-white-200 p-4 pt-6">
          <div className="flex flex-col gap-1">
            <p className="font-bold text-2xl">{chartData.title}</p>
            {chartData.subtitle && <p className="font-light text-[10px] leading-3">{chartData.subtitle}</p>}
          </div>

          {!!legend?.length && <ChartLegend legend={legend} />}
        </div>
      )}

      <ChartContainer config={chartData.series_settings} className={cn('min-h-[200px] w-full', className)}>
        <BarChartComponent data={chartData.data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={chartData.primary.query_identifier} tickMargin={8} axisLine={false} />
          <YAxis />
          {Object.values(chartData.series_settings).map(({ query_identifier, color, format_parser, stack_id }) => {
            return (
              <Bar key={query_identifier} dataKey={query_identifier} stackId={stack_id} style={{ fill: color }}>
                <LabelList
                  formatter={(value: string | number) => barChartLabelFormatter(value, format_parser)}
                  position="inside"
                  dataKey={query_identifier}
                  className="fill-white-800"
                />
              </Bar>
            )
          })}
        </BarChartComponent>
      </ChartContainer>
    </div>
  )
}
