import ResumeItem from "@/common/components/ResumeItem";
import Table from "@/common/components/Table";
import { formatCurrency } from "@/common/utils/formatters";
import { useParams } from "react-router-dom";
import useBillingBill from "../../hooks/useBillingBill";
import { twMerge } from "tailwind-merge";
import { billingBillType } from "../../constants/billingBillType.constant";
import { billingBillStatus } from "../../constants/billingBillStatus.constant";
import { MovementsTotalizerTransformer } from "@/features/movementsTotalizer/services/movementsTotalizerTransformer";
import { totalizerFeesColumns, totalizerProductsColumns } from "@/features/movementsTotalizer/constants/movementsTotalizerTableColumns.constant";

const ResumeTab = () => {
  const { id } = useParams();

  const {
    data: bill,
    isPending: billIsPending,
  } = useBillingBill({ id });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-between">
        <div className="flex items-end gap-4">
          <ResumeItem
            label="Status"
            value={bill?.status ? billingBillStatus[bill.status] : '--'}
            className="text-yellow-400"
          />

          <ResumeItem
            label="Tipo de fatura"
            value={bill?.kind ? billingBillType[bill.kind] : '--'}
          />
        </div>

        <div className="flex items-end gap-4">
          {(bill?.kind === 'consumption' || bill?.kind === 'payment') && (
            <>
              <ResumeItem
                label="Consumo"
                value={formatCurrency(Number(bill?.movements_totalizer?.consumed_total) || 0)}
              />

              <ResumeItem
                label="Descontos"
                value={formatCurrency(Number(bill?.movements_totalizer?.discount_total) || 0)}
              />
            </>
          )}

          {(bill?.kind === 'payment') && (
            <>
              <ResumeItem
                label="Valor em tarifas"
                value={formatCurrency(Number(bill?.movements_totalizer?.fees_total) || 0)}
              />
            </>
          )}

          <ResumeItem
            label="Valor a receber"
            value={formatCurrency(Number(bill?.total_amount) || 0)}
            className="text-green-400"
          />
        </div>
      </div>

      {bill?.movements_totalizer?.product_movements?.length !== 0 && (
        <div className="flex flex-col gap-4">
          <h5>Produtos</h5>
          <Table
            className="max-h-[210px]"
            rows={MovementsTotalizerTransformer.productsTableTransformer()(bill?.movements_totalizer?.product_movements) || []}
            columns={totalizerProductsColumns}
            isLoadingData={billIsPending}
            loadingRows={2}
            page={1}
            totalPages={1}
          />
        </div>
      )}

      {bill?.movements_totalizer?.fees?.length !== 0 && (
      <div className="flex flex-col gap-4">
        <h5>Tarifas</h5>
        <Table
          className={twMerge('max-h-[210px]', bill?.movements_totalizer?.fees?.length === 0 ? 'overflow-hidden [&>*]:-mt-20 pt-4' : '')}
          rows={MovementsTotalizerTransformer.feesTableTransformer()(bill?.movements_totalizer?.fees) || []}
          columns={totalizerFeesColumns}
          isLoadingData={billIsPending}
          loadingRows={2}
          page={1}
          totalPages={1}
        />
      </div>
      )}

      {bill?.movements_totalizer?.special_fees?.length !== 0 && (
        <div className="flex flex-col gap-4">
          <h5>Tarifas Especiais</h5>
          <Table
            className={twMerge('max-h-[210px]', bill?.movements_totalizer?.special_fees?.length === 0 ? 'overflow-hidden [&>*]:-mt-20 pt-4' : '')}
            rows={MovementsTotalizerTransformer.feesTableTransformer()(bill?.movements_totalizer?.special_fees) || []}
            columns={totalizerFeesColumns}
            isLoadingData={billIsPending}
            loadingRows={2}
            page={1}
            totalPages={1}
          />
        </div>
      )}
    </div>
  );
};

export default ResumeTab;
