import Box from '@/common/components/Box'
import Button from '@/common/components/Button'
import { ChartRenderer } from '@/common/components/ChartRenderer'
import { ErrorMessageWrapper } from '@/common/components/ErrorMessageWrapper'
import { ShowHeader } from '@/common/components/ShowHeader'
import { DatePickerWithRange } from '@/common/components/ui/dateRangePicker'
import { useFilterDate } from '@/common/hooks/useFilterDate'
import { useFilterParams } from '@/common/hooks/useFilterParams'
import { ChartData } from '@/common/types/chart.type'
import { useDashboardData } from '@/features/company/hooks/useDashboardData'
import { Link } from 'react-router-dom'
import { useMineCompany } from '../../hooks/useMineCompany'
import { Mapper } from '@/common/services/mapper'
import { ChartDataTransformer } from '@/common/services/chartDataTransformer'

const Company = () => {
  const { filterParams, setFilterParams } = useFilterParams()
  const { date, formattedDate } = useFilterDate(filterParams)

  const { data: company } = useMineCompany()

  const {
    data: dashboardData,
    isFetching,
    error: dashBoardError = {} as any,
    refetch: refetchDashboard,
  } = useDashboardData<ChartData[]>({
    filters: {
      startDate: formattedDate.from,
      endDate: formattedDate.to,
    },
    mapper: Mapper.mapWithTransformer({
      transformer: ChartDataTransformer.chartTransformer(),
    }),
  })

  return (
    <div>
      <ShowHeader
        logoUrl={company?.general_information.company_logo?.url}
        title={company?.general_information.fantasy_name}
        buttons={
          <Link to="/company/mine">
            <Button variant="outline-disabled">Ver perfil</Button>
          </Link>
        }
      />

      <ErrorMessageWrapper
        className="h-[calc(100vh-192px)]"
        statusCode={dashBoardError?.status}
        refetchFunction={refetchDashboard}
      >
        <div className="flex flex-col gap-6">
          <div className="flex">
            <DatePickerWithRange date={date} setDate={(date) => setFilterParams((params) => ({ ...params, date }))} />
          </div>

          {isFetching ? (
            <Box>Carregando...</Box>
          ) : (
            <div className="grid grid-cols-12 gap-6">
              {dashboardData?.map((chartData) => (
                <ChartRenderer key={chartData.title} chartData={chartData} className="max-h-64" />
              ))}
            </div>
          )}
        </div>
      </ErrorMessageWrapper>
    </div>
  )
}

export default Company
