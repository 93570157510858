import Box from "@/common/components/Box";
import Skeleton from "@/common/components/Skeleton";
import Table from "@/common/components/Table";
import { productsSummaryDefaultColumns } from "../../constants/productsSummaryDefaultColumns.constant";

const FiscalDocumentGroupLoading = () => {
  return (
    <Box className="h-[calc((100vh-88px-2rem-1.5rem))] overflow-auto flex gap-6">
      <div className={`flex flex-col gap-6 h-max w-full`}>
        <div className="flex flex-col gap-3">
          <h3 className="text-xl font-bold">Resumo do lote</h3>

          <div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <p className="italic font-light text-xs">Estabelecimento</p>

                <div className="flex items-center gap-2">
                  <Skeleton circle className="w-8 h-8" />

                  <Skeleton className="w-44" />
                </div>
              </div>

              <div className="flex gap-8">
                <div className="flex flex-col gap-1">
                  <p className="italic font-light text-xs">Abertura</p>
                  <Skeleton className="w-28" />
                </div>

                <div className="flex flex-col gap-1">
                  <p className="italic font-light text-xs">Fechamento</p>
                  <Skeleton className="w-28" />
                </div>
              </div>

              <div className="flex gap-8">
                <div className="flex flex-col gap-1">
                  <p className="italic font-light text-xs">Empresa</p>

                  <div className="flex items-center gap-2">
                    <Skeleton circle className="w-8 h-8" />

                    <Skeleton className="w-44" />
                  </div>
                </div>

                <div className="flex flex-col gap-1">
                  <p className="italic font-light text-xs">Filial</p>

                  <Skeleton className="w-44" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h3>Produtos</h3>

          <Table
            className="max-h-[210px]"
            columns={productsSummaryDefaultColumns}
            rows={[]}
            isLoadingData={true}
            hasPagination={false}
            loadingRows={1}
            page={1}
            totalPages={1}
          />
        </div>

        <div className="flex">
          <div className="w-1/2">
            <div className="flex flex-col gap-3">
              <h3 className="text-xl font-bold">Dados para emissão</h3>

              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-1">
                  <p className="italic font-light text-xs">CNPJ</p>

                  <Skeleton className="w-56" />
                </div>

                <div className="flex flex-col gap-1">
                  <p className="italic font-light text-xs">Razão social</p>

                  <Skeleton className="w-64" />
                </div>

                <div className="flex flex-col gap-1">
                  <p className="italic font-light text-xs">Inscrição estadual</p>

                  <Skeleton className="w-32" />
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-1/2 flex-col gap-3">
            <h3 className="text-xl font-bold">Nota fiscal</h3>

            <Skeleton className="h-24" />
          </div>
        </div>
      </div>
    </Box>
  );
};

export default FiscalDocumentGroupLoading;
