import TextField from '@/common/components/TextField'
import { BankAccountInformation } from '@/features/company/types/company.type'

interface BankAccountSectionProps {
  establishmentCompanyId?: string
  bankAccountInformation?: BankAccountInformation
}

export const BankAccountSection = ({ bankAccountInformation }: BankAccountSectionProps) => {
  return (
    <div className="flex flex-col gap-4 rounded-sm p-4 bg-white-100">
      <p className="text-[18px] font-bold">Detalhes bancários</p>

      <TextField
        label="Banco"
        value={
          !!bankAccountInformation?.bank_institution
            ? `${bankAccountInformation?.bank_institution.code} - ${bankAccountInformation?.bank_institution.name}`
            : undefined
        }
        disabled
      />

      <div className="flex gap-2">
        <TextField label="Agência (com dígito)" className="grow" value={bankAccountInformation?.agency} disabled />

        <TextField label="Conta (com dígito)" className="grow" value={bankAccountInformation?.account} disabled />
      </div>

      <TextField label="Favorecido" value={bankAccountInformation?.payee_name} disabled />
    </div>
  )
}
