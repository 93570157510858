import axios from "axios";

export const publicAxios = axios.create({
  baseURL: `${import.meta.env.VITE_BASE_URL}/api/establishment`
});

export const publicDomainsAxios = axios.create({
  baseURL: `${import.meta.env.VITE_BASE_URL}/api/public`
});

export const privateAxios = axios.create({
  baseURL: `${import.meta.env.VITE_BASE_URL}/api/establishment`,
  headers: { 'Content-type': 'application/json' },
  withCredentials: true
});