import { DropdownMenuItem } from "@radix-ui/react-dropdown-menu";
import { DropdownMenu as UiDropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "../ui/dropdownMenu";
import Button from "../Button";
import { ChevronDown } from "lucide-react";
import { twMerge } from "tailwind-merge";
import React from "react";

interface DropdownMenuProps {
  triggerLabel: string;
  triggerClassName?: string;
  contentClassName?: string;
  itemClassName?: string;
  options: {
    label: string;
    onClick: () => void;
    className?: string;
    disabled?: boolean;
  }[];
}

const DropdownMenu = ({
  triggerLabel,
  triggerClassName,
  contentClassName,
  itemClassName,
  options,
}: DropdownMenuProps) => {
  return (
    <UiDropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="neutral" className={triggerClassName}>
          {triggerLabel}

          <ChevronDown />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className={twMerge('bg-black-900 border-none rounded-sm shadow-[0px_0px_8px_4px_rgba(0,_0,_0,_0.2)]', contentClassName)}>
        {options.map((option) => (
          <DropdownMenuItem
            key={`dropdown-menu-item-${React.useId()}`}
            className={twMerge(
              'text-white-800 font-normal text-sm px-3 py-2 cursor-pointer hover:bg-white-100 hover:outline-none min-w-[120px]',
              itemClassName,
              option.disabled && 'cursor-default text-white-300 hover:bg-transparent',
              option.className
            )}
            onClick={option.onClick}
            disabled={option.disabled}
          >
            {option.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </UiDropdownMenu>
  );
};

export default DropdownMenu;