import Button from "@/common/components/Button";
import { ErrorMessageWrapper } from "@/common/components/ErrorMessageWrapper";
import { ShowHeader } from "@/common/components/ShowHeader";
import Tabs from "@/common/components/Tabs";
import useBillingBill from "@/common/hooks/queries/useBillingBill";
import { useParams } from "react-router-dom";
import { billingBillTabs } from "../../constants/tabs.constant";
import { DownloadIcon } from "lucide-react";
import { formatDate } from "@/common/utils/formatters";

const BillingBillsShow = () => {
  const { id } = useParams();

  const {
    data: bill,
    isPending: billIsPending,
    error: billError = {} as any,
    refetch: billRefetch,
  } = useBillingBill({ id });

  return (
    <div>
      <ShowHeader
        backTo="/billing-bills"
        LogoPlaceholder={<></>}
        titleClassName="font-medium text-lg"
        title={`Fatura ${bill?.opened_at ? `${formatDate(new Date(bill?.opened_at))} -` : ''} ${bill?.closed_at ? formatDate(new Date(bill?.closed_at)) : ''}`}
        buttons={(
          <Button
            type="button"
            className="shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)]"
            disabled={billIsPending || billError?.status === 403}
          >
            Baixar fatura

            <DownloadIcon strokeWidth={1.5} className="h-5" />
          </Button>
        )}
      />

      <ErrorMessageWrapper
        className="max-h-[calc(100vh-128px)]"
        statusCode={billError?.status}
        refetchFunction={billRefetch}
      >
        <Tabs
          tabsListClassName="w-full bg-transparent rounded-none"
          tabsTriggerClassName="h-8 w-full rounded-none border-b border-[#444444] text-white-800 text-xs data-[state=active]:bg-transparent data-[state=active]:text-white-800 data-[state=active]:shadow-none data-[state=active]:border-blue-800"
          tabsContentClassName="mt-6"
          tabsList={billingBillTabs}
        />
      </ErrorMessageWrapper>
    </div>
  );
};

export default BillingBillsShow;
