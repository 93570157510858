import Table from "@/common/components/Table";
import TextField from "@/common/components/TextField";
import { Search } from "lucide-react";
import { useFilterParams } from "@/common/hooks/useFilterParams";
import useDebounce from "@/common/hooks/useDebounce";
import ListFilters from "@/common/components/ListFilters";
import { Mapper } from "@/common/services/mapper";
import { useBillingBillEvents } from "../../hooks/useBillingBillEvents";
import { useParams } from "react-router-dom";
import billEventsTableColumns from "../../constants/billEventsTableColumns.constant";
import { EstablishmentUserData } from "@/features/users/types/establishmentUsers";
import { useEstablishmentUsers } from "@/features/users/hooks/useEstablishmentUsers";
import { BillingEventTransformer } from "@/features/billingEvents/services/billingEventTransformer";
import { eventableTypeOptions } from "@/features/billingEvents/constants/eventableType.constant";

const EventsTab = () => {
  const { id } = useParams();

  const { filterParams, setFilterParams } = useFilterParams();

  const [search, setSearch] = useDebounce(filterParams.fiscal_document_code || '');
  const [searchUser, setSearchUser] = useDebounce('');

  const { data: usersOptions } = useEstablishmentUsers({
    filters: { search: searchUser },
    mapper: Mapper.mapToOptions<EstablishmentUserData>({ valueFieldName: 'id', labelFieldName: 'full_name' }),
  });

  const { data: events, meta, isPending: eventsIsPending } = useBillingBillEvents({
    filters: {
      id,
      page: Number(filterParams.page) || 1,
      search: search,
      companyBranchId: filterParams.clientBranch?.value,
      authorId: filterParams.author?.value,
      equipmentId: filterParams.equipment?.value,
      eventableType: filterParams.type?.value,
    },
    mapper: Mapper.mapWithTransformer({ transformer: BillingEventTransformer.tableTransformer() }),
  })

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <TextField
          LeftIcon={<Search />}
          placeholder="Pesquisar"
          className="rounded-sm shadow-[4px_0px_16px_0px_rgba(0,_0,_0,_0.5)]"
          onChange={(e) => {
            setSearch((e.target as HTMLInputElement).value);
            setFilterParams((prev) => ({ ...prev, search: (e.target as HTMLInputElement).value }));
          }}
          defaultValue={filterParams.search}
        />

        <ListFilters
          filters={[
            {
              className: 'min-w-40',
              placeholder: 'Tipo de transação',
              options: [{ value: '', label: 'Todos' }, ...eventableTypeOptions],
              onChange: (e: any) => setFilterParams((prev) => ({ ...prev, type: e.value ? e : null })),
              value: filterParams.type || '',
            },
            {
              className: 'min-w-40',
              placeholder: 'Autor',
              options: [{ label: 'Todos', value: '' }, ...(usersOptions || [])],
              onChange: (e: any) => setFilterParams((params) => ({ ...params, author: e.value ? e : null })),
              onInputChange: setSearchUser,
              value: filterParams.author || '',
            },
          ]}
        />
      </div>

      <div>
        <Table
          className="max-h-[calc(100vh-208px-7rem)]"
          rows={events || []}
          columns={billEventsTableColumns}
          isLoadingData={eventsIsPending}
          page={meta.current_page}
          totalPages={meta.total_pages}
          totalCount={meta.total}
          hasPagination
          setPage={(page) => setFilterParams((prev) => ({ ...prev, page }))}
          rowEmptyValue="--"
        />
      </div>
    </div>
  );
};

export default EventsTab;
