import AddressForm from '@/common/components/AddressForm';
import Box from '@/common/components/Box';
import { ErrorMessageWrapper } from '@/common/components/ErrorMessageWrapper';
import { ShowHeader } from '@/common/components/ShowHeader';
import { AddressData } from '@/common/types/address';
import { AccreditationInformationSection } from '@/features/company/components/Profile/AccreditationInformationSection';
import { BankAccountSection } from '@/features/company/components/Profile/BankAccountSection';
import { GeneralInformationSection } from '@/features/company/components/Profile/GeneralInformationSection';
import { useMineCompany } from '@/features/company/hooks/useMineCompany';

const CompanyProfile = () => {
  const {
    data: company,
    isPending: companyIsPending,
    error: companyError = {} as any,
    refetch: refetchCompany,
  } = useMineCompany();

  return (
    <div>
      <ShowHeader
        logoUrl={company?.general_information.company_logo?.url}
        title={company?.general_information.fantasy_name}
        backTo="/company"
      />

      <ErrorMessageWrapper
        className='max-h-[calc(100vh-128px)]'
        statusCode={companyError?.status}
        refetchFunction={refetchCompany}
      >
        <Box className="flex flex-col gap-6 h-[calc(100vh-128px)] overflow-y-scroll">
          <AccreditationInformationSection
            matrixBranch={company?.matrix_branch}
            productCategories={company?.product_categories}
          />

          <GeneralInformationSection
            generalInformation={company?.general_information}
          />

          <div className="flex flex-col gap-4">
            <p className="text-[18px] font-bold">
              Endereço
            </p>

            {!companyIsPending && (
              <AddressForm
                initialAddress={(company?.general_information?.address || {}) as AddressData}
                errors={{}}
                disableFields
              />
            )}
          </div>

          <BankAccountSection bankAccountInformation={company?.matrix_branch?.bank_account_information} />
        </Box>
      </ErrorMessageWrapper>
    </div>
  );
};

export default CompanyProfile;
