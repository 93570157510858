import {
  AreaChart,
  Coins,
  DollarSign,
  FileSpreadsheet,
  FileText,
  PieChart,
  Receipt,
  Repeat2,
  SquareX,
  Table2,
  TableProperties,
  TicketIcon,
  UserRound,
  WandSparkles,
} from 'lucide-react'

const routes = [
  {
    label: 'Minha empresa',
    Icon: <PieChart strokeWidth={1} />,
    path: '/company',
    permission: 'Establishment::Dashboard::Groups::Mine',
  },
  {
    label: 'Relatórios',
    Icon: <FileSpreadsheet strokeWidth={1} />,
    path: '/reports',
    permission: 'Establishment::Reports::Entries::PermittedReportsFilter',
  },
  {
    label: 'Financeiro',
    Icon: <Coins strokeWidth={1} />,
    path: [
      {
        label: 'Transações',
        Icon: <Receipt strokeWidth={1} />,
        path: '/billing-events',
        permission: 'Establishment::Billing::Events::Filter',
      },
      {
        label: 'Totalizadores',
        Icon: <AreaChart strokeWidth={1} />,
        path: '/movements-totalizer',
        permission: 'Establishment::Companies::MovementsTotalizer',
      },
      {
        label: 'Faturas',
        Icon: <FileText strokeWidth={1} />,
        path: '/billing-bills',
        permission: 'Establishment::Billing::Bills::Filter',
      },
    ],
  },
  {
    label: 'Recolha de notas fiscais',
    Icon: <Repeat2 strokeWidth={1} />,
    path: [
      {
        label: 'Por transações',
        Icon: <Coins strokeWidth={1} />,
        path: '/events-with-fiscal-document-requirement',
        permission: 'Establishment::Billing::FiscalDocumentGroups::Filter',
      },
      {
        label: 'Por lote',
        Icon: <TableProperties strokeWidth={1} />,
        path: '/billing-fiscal-document-groups',
        permission: 'Establishment::Billing::FiscalDocumentGroups::Filter',
      },
    ],
  },
  {
    label: 'Tabela de preços',
    Icon: <Table2 strokeWidth={1} />,
    path: '/product-prices',
    permission: 'Establishment::ProductPrices::Filter',
  },
  {
    label: 'Transações',
    Icon: <DollarSign strokeWidth={1} />,
    path: [
      {
        label: 'Consumo',
        Icon: <Receipt strokeWidth={1} />,
        path: '/consumption',
        permission: 'Establishment::Client::Consumptions::Create',
      },
      {
        label: 'Consulta',
        Icon: <WandSparkles strokeWidth={1} />,
        path: '/card-current-limit',
        permission: 'Establishment::Client::Cards::CurrentLimits',
      },
      {
        label: 'Estorno',
        Icon: <SquareX strokeWidth={1} />,
        path: '/refund',
        permission: 'Establishment::Billing::Refunds::Create',
      },
    ],
  },
  {
    label: 'Usuários',
    Icon: <UserRound strokeWidth={1} />,
    path: '/users',
    permission: 'Establishment::Users::Filter',
  },
  {
    label: 'Chamados',
    Icon: <TicketIcon strokeWidth={1} />,
    path: '/support',
    permission: 'Establishment::Support::Tickets::Filter',
  },
]

export default routes