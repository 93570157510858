import * as React from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { Button, buttonVariants } from "@/common/components/ui/button";
import { Calendar } from "@/common/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/common/components/ui/popover";
import { cn } from "@/common/utils/cn";
import { VariantProps } from "class-variance-authority";

interface DatePickerProps extends VariantProps<typeof buttonVariants> {
  className?: string;
  inputClassName?: string;
  label?: string;
  date: Date | undefined;
  setDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  markAsRequired?: boolean,
}

export function DatePicker({
  className,
  inputClassName,
  variant,
  label,
  date,
  setDate,
  markAsRequired,
}: DatePickerProps) {
  return (
    <div className={cn("flex flex-col", className)}>
      {label ? (
        <label htmlFor="date" className="text-xs self-start mb-1 cursor-pointer">
          {label}
          {markAsRequired && <span className="text-danger-soft"> *</span>}
        </label>
      ) : null}
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={variant ?? "badge"}
            size={"badge"}
            className={cn(
              "justify-start text-left font-normal bg-white-100 rounded-xl",
              inputClassName,
              !date && "text-muted-foreground",
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />

            {date ? (
              format(date, "dd/MM/yy")
            ) : (
              <span>Selecione uma data</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0 bg-[#212023] border-neutral-op-8 dark" align="start">
          <Calendar
            initialFocus
            mode="single"
            defaultMonth={date}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
