import usePrivateAxios from '@/common/api/usePrivateAxios'
import { defaultTransformerResponse } from '@/common/constants/defaultTransformerResponse.constant'
import { Mapper } from '@/common/services/mapper'
import { MetaResponse } from '@/common/types/metaResponse'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

type UseCardApplicableProductsProps<T> = {
  filters?: {
    cardId?: string
  }
  mapper?: (data: any) => T
}

export const useCardApplicableProducts = <T,>({
  filters: { cardId } = {},
  mapper = Mapper.default<T>(),
}: UseCardApplicableProductsProps<T>) => {
  const privateAxios = usePrivateAxios()

  const { data: { data, meta } = defaultTransformerResponse<T>(), ...rest } = useQuery({
    queryKey: ['cardApplicableProducts'],
    enabled: !!cardId,
    queryFn: () => {
      return privateAxios.get(`/client/cards/${cardId}/applicable_products`)
    },
    select: (data: { data: { data: any[]; meta: MetaResponse } }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      }
    },
    placeholderData: keepPreviousData,
  })

  return { data, meta, ...rest }
}
